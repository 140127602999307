<template>
    <div class="h-90 ml-2 mr-2">
        <div class="row align-items-center justify-content-center" v-if="ajax_call_in_progress">
            <div class="col-lg-12 py-5 px-3 mt-3">
                <div class="d-flex align-items-center justify-content-center">
                    <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                </div>
            </div>
        </div>
        <div v-else class="pb-10 h-100 bottom-padding">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToCompanyList">Company</span>
                        <span class="pointer" @click="goToListView">Departments</span>
                        <div v-if="$route.path.startsWith('/departmentunit/create/cmpid')">Create New</div>
                        <div v-else-if="$route.path.startsWith('/departmentunit/view/cmpid')">View</div>
                        <div v-else>Edit</div>
                    </div>
                </div>
            </div>
            <button class="btn btn-new-secondary ml-5 float-right" @click="editDepartment" v-if="$route.path.startsWith('/departmentunit/view/cmpid')" >Edit</button>
            <!-- <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="header-slant-tab">
                        <span class="text-secondary fs-14" v-if="$route.path.startsWith('/departmentunit/create/cmpid')">Create New</span>
                        <span class="text-secondary fs-14" v-else-if="$route.path.startsWith('/departmentunit/view/cmpid')">View</span>
                        <span class="text-secondary fs-14" v-else>Edit</span>
                    </div>
                    <div class="header-slant-tab-border"></div>

                </div>

            </div> -->
            <div class="create-company-container w-100 h-100">
                <div class="text-center fw-600 mb-3">
                    <span class="text-secondary fs-16" v-if="$route.path.startsWith('/departmentunit/create/cmpid')">Create New</span>
                        <span class="text-secondary fs-16" v-else-if="$route.path.startsWith('/departmentunit/view/cmpid')">View</span>
                        <span class="text-secondary fs-16" v-else>Edit</span>
                </div>
                <div class="create-company-header d-flex align-items-center justify-content-between">
                    <span class="fs-14 pl-4 text-white">Department Information</span>
                    <span class="pointer" @click="showHideContent('depart_info')">
                        <i class="text-white" :class="{'icon-chevron-double-up':is_show_hide_depart_info, 'icon-chevron-double-down':!is_show_hide_depart_info}" style="font-size: 20px;"></i>
                    </span>
                </div>
                <div :class="{'d-none':!is_show_hide_depart_info}" class="px-6">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Company Name<span class="pl-1 text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" v-validate="'required'" :readonly="true" :disabled="true" v-model="company_name" class="input-field input-box "
                                        placeholder="Enter Company Name" name="company_name" />
                                    <span class="pointer">
                                        <i class="icon icon-check-circle fs-30 mt-9 ml-2"
                                            style="color: rgb(18, 136, 7);"></i>
                                    </span>
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('company_name')">
                                    {{errors.first('company_name')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Department Name<span class="pl-1 text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" v-validate="'required'" v-model="department_name" class="input-field input-box " name="department_name"
                                        placeholder="Enter Department Name" />
                                    <span>
                                        <!-- <el-tooltip class="item" effect="dark" content="0" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                            </el-tooltip> -->
                                    </span>
                                </div>
                                <div >
                                    <span class="text-danger" v-show="errors.has('department_name')">
                                        {{errors.first('department_name')}}
                                    </span>
                                    <!-- <span class="input-field-length float-right">{{ department_name.length }}/100</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Business Unit Name<span class="pl-1 text-danger">*</span></label>
                                <div class="d-flex align-items-center">
                                    <multiselect class="diginew-multiselect filterName multiselect-height-issue-fix" v-validate="'required'" label="business_unit_name" name="business_unit_name" :loading="business_unit_loading" @open="getBusinesUnitOptions" :show-labels="false" :searchable="true" :options="business_unit_name_options" v-model="business_unit_name" placeholder="Select Business Unit Name">
                                        <template slot="noOptions"> 
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                    <!-- <el-tooltip class="item" effect="dark" content="0" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                        </el-tooltip> -->
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('business_unit_name')">
                                    {{errors.first('business_unit_name')}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <span class="input-label">Started On (DD/MM/YYYY)<span class="pl-1 text-danger">*</span></span>
                                <div class="d-flex align-items-center mt-2">
                                    <date-picker
                                        class="profile-date-picker calendar-edit-per input-field pt-0 pb-0 pr-0 date-picker-padding-issue-fix"
                                        value-type="format" lang="en" v-model="started_on" :not-after="new Date()" type="date"
                                        :clearable="false"
                                        :editable="false" style="padding-left: 0px !important;"
                                        format="DD/MM/YYYY"></date-picker>
                                </div>
                                <span class="invalid-feedback-form text-danger" v-if="is_started_on">
                                    Started On is required
                                </span>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Business Unit Code *</label>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" v-validate="'required'" v-model="department_name" class="input-field" name="department_name"
                                        placeholder="1012" />
                                    <span>
                                        <el-tooltip class="item" effect="dark" content="0" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                            </el-tooltip> -->
                                    <!-- </span>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Description</label>
                                <textarea rows="5" type="text" v-model="description" maxlength="4000" class="input-field input-textarea"
                                    name="decription" placeholder="Enter text here" />
                                    <div class="text-right">
                                        <span class="input-field-length float-right">{{ description.length }}/4000</span>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="create-company-container w-100 h-100" style="border-top-left-radius: 0;">
                <div class="create-company-header d-flex align-items-center justify-content-between">
                    <span class="fs-14 text-white">Contact Information</span>
                    <span class="pointer" @click="showHideContent('contact_info')">
                        <i class="text-white" :class="{'icon-chevron-double-up':is_show_hide_contact_info, 'icon-chevron-double-down':!is_show_hide_contact_info}" style="font-size: 20px;"></i>
                    </span>
                </div>
                <div :class="{'d-none':!is_show_hide_contact_info}" class="px-6">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <label class="input-label">Department Head</label>
                                <div class="d-flex align-items-center">
                                    <multiselect class="diginew-multiselect multiselect-height-issue-fix" name="department_head" :show-labels="false" label="name" @open="getDepartHeadOptions" :loading="depart_head_loading" :searchable="true" :options="department_head_options" v-model="department_head" placeholder="Select Department Head">
                                        <template slot="noOptions">
                                            <div class="text-secondary text-center fw-600">List is Empty</div>
                                        </template>
                                        <template slot="noResult">
                                            <div class="text-secondary text-center fw-600">No Results Found</div>
                                        </template>
                                    </multiselect>
                                    <!-- <el-tooltip class="item" effect="dark" content="0" placement="top">
                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                        </el-tooltip> -->
                                </div>
                                <!-- <span class="invalid-feedback-form text-danger" v-show="errors.has('department_head')">
                                    {{errors.first('department_head')}}
                                </span> -->
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <span  class="input-label d-flex align-items-center justify-content-between">
                                    <span> Primary Email
                               </span>
                                   <span class="pointer" @click="addAlternateEmail">
                                       <i class="icon icon-plus-circle text-success" style="color: #008a63 !important;"></i>
                                   </span>
                               </span>
                                <div class="d-flex align-items-center">
                                    <div class="d-flex align-content-center w-100">
                                        <div class="d-flex align-items-center input-field input-box w-100">
                                            <input type="text"
                                                class="form-control-email-dropdown w-100"
                                                v-model="primary_email"
                                                name="primary_email"
                                                v-validate="{required: true,regex: /^[a-zA-Z0-9-_.]+$/}"
                                                autocomplete="off"
                                                maxlength="100"
                                                placeholder="Enter Primary Email" />
                                            <span class="input-group-addon-email"
                                                style="font-size: 14px;">@</span>
                                            <span class="fs-14" style="">
                                                {{company_department_domain_name.replace(/.+\/\/|www.|\+/g, '')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- <span class="invalid-feedback-form text-danger" v-show="errors.has('primary_email')">
                                    {{errors.first('primary_email')}}
                                </span> -->
                            </div>
                        </div>
                        <div v-for="(alt_email, alt_email_index) in alternate_emails" :key="alt_email_index+'alt_email'" class="col-lg-4 col-md-6 col-sm-12 mt-6">
                            <div class="w-100">
                                <span class="input-label d-flex align-items-center justify-content-between">Alternative Email {{alt_email_index+1}}
                                    <span class="pointer" @click="deleteAlternateEmail(alt_email_index)">
                                        <i class="icon icon-minus-circle text-danger"></i>
                                    </span>
                                </span>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" v-validate="'email'" data-vv-as="Alternate Email" v-model="alternate_emails[alt_email_index]" class="input-field input-box "
                                        :placeholder="'Enter Alternative E-mail ' + (alt_email_index+1)" :name="'alternate_emails'+alt_email_index" />
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('alternate_emails'+alt_email_index)">
                                    {{errors.first('alternate_emails'+alt_email_index)}}
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="w-100 mt-6">
                                <span  class="input-label d-flex align-items-center justify-content-between">
                                     <span>Primary Phone Number<span class="pl-1 text-danger">*</span>
                                </span>
                                    <span class="pointer" @click="addAlternatePhone">
                                        <i class="icon icon-plus-circle text-success" style="color: #008a63 !important;"></i>
                                    </span>
                                </span>
                                <div class="d-flex align-items-center">
                                    <div class="leftType ml-0">
                                        <div class="d-flex">
                                            <multiselect v-model="primary_phone_type"
                                                class="diginew-multiselect phone-number pointer icon_space multiselect-height-issue-fix"
                                                :options="primary_phone_type_options"
                                                :multiple="false" :searchable="true" :internal-search="true"
                                                :show-labels="false" :clear-on-select="false"
                                                placeholder=""  style="width: 80px !important;height: 38px !important;"
                                                :close-on-select="true" :options-limit="280" :max-height="200"
                                                :show-no-results="true" :hide-selected="false">
                                                <template slot="singleLabel" slot-scope="props">
                                                    <img v-if="$route.path.startsWith('/departmentunit/create/cmpid')" class="option__image" :src="props.option.image" :alt="props.option.name" style="width:22px;">
                                                    <span v-else style="font-size: 12px;width:22px;">{{ props.option.name }}</span>
                                                </template>
                                                <template slot="option" slot-scope="{ option }">
                                                    <div class="d-flex flex-row">
                                                        <img :src="option.image" style="width: 10px;" />
                                                        <span class="ml-4">
                                                            <span style="font-size: 14px">{{ option.name }}</span>
                                                        </span>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                        <div v-if="$route.path.startsWith('/departmentunit/create/cmpid')">
                                            <vue-tel-input name="primary_phone" :enabledCountryCode="false"
                                                :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                class="teleContact w-100" ref="telInput"
                                                placeholder="Enter Primary Phone Number"
                                                :defaultCountry="primary_phone_number.country_code"
                                                @onInput="departPhone($event)"
                                                v-validate="{required:true, min:12}"
                                                v-model="primary_phone_number.phone_number">
                                            </vue-tel-input>
                                        </div>
                                        <!-- {{primary_phone_number.country_code}} -->
                                        <div v-if="$route.path.startsWith('/departmentunit/edit/cmpid')">
                                            <vue-tel-input name="primary_phone" :enabledCountryCode="false"
                                                :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                class="teleContact w-100" ref="telInput"
                                                placeholder="Enter Primary Phone Number"
                                                :defaultCountry="primary_phone_number.country_code"
                                                @onInput="departPhone($event)"
                                                v-validate="{required:true, min:12}"
                                                v-model="primary_phone_number.phone_number">
                                            </vue-tel-input>
                                        </div>
                                    </div>
                                </div>
                                <span class="invalid-feedback-form text-danger" v-show="errors.has('primary_phone')">
                                    {{errors.first('primary_phone')}}
                                </span>
                            </div>
                        </div>
                        <div v-for="(alternate_phone, alt_phone_index) in alternate_phone_numbers" :key="alt_phone_index+'alt'" class="col-lg-4 col-md-6 col-sm-12 mt-6">
                            <span class="input-label d-flex align-items-center justify-content-between">Alternate Phone Number {{alt_phone_index+1}}
                                <span class="pointer" @click="deleteAlternatePhone">
                                    <i class="icon icon-minus-circle text-danger"></i>
                                </span>
                            </span>
                            <div class="d-flex align-items-center">
                                <div class="leftType ml-0">
                                    <div class="d-flex">
                                        <multiselect v-model="alternate_phone.phone_type"
                                            class="diginew-multiselect phone-number pointer icon_space multiselect-height-issue-fix" id="ajax"
                                            :options="primary_phone_type_options" 
                                            :multiple="false" :searchable="true" :internal-search="true"
                                            :show-labels="false" style="width: 80px !important;height: 38px !important;" placeholder=""
                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                            <template slot="singleLabel" slot-scope="props">
                                                <img v-if="$route.path.startsWith('/departmentunit/create/cmpid')" class="option__image" :src="props.option.image" :alt="props.option.name" style="width: 22px;">
                                                <span v-else style="font-size: 12px;width: 22px;" >{{ props.option.name }}</span>
                                            </template>
                                            <template slot="option" slot-scope="{ option }">
                                                <div class="d-flex flex-row">
                                                    <img
                                                        :src="option.image"
                                                        style="width: 10px;" />
                                                    <span class="ml-4">
                                                        <span style="font-size: 14px">{{ option.name }}</span>
                                                    </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                    <div v-if="$route.path.startsWith('/departmentunit/create/cmpid')">
                                        <vue-tel-input
                                            :name="'alternate_phone'+alt_phone_index"
                                            :enabledCountryCode="false"
                                            :defaultCountry="primary_phone_number.country_code"
                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                            class="w-100" ref="telInput"
                                            :placeholder="'Enter Alternate Phone Number '+(alt_phone_index+1)"
                                            data-vv-as="Alternate Phone"
                                            @onInput="departAltPhone($event, alt_phone_index)"
                                            v-validate="{min:12}"
                                            v-model="alternate_phone.phone_number">
                                        </vue-tel-input>
                                    </div>
                                    <div v-if="$route.path.startsWith('/departmentunit/edit/cmpid')">
                                        <vue-tel-input
                                            :name="'alternate_phone'+alt_phone_index"
                                            :enabledCountryCode="false"
                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                            class="w-100" ref="telInput"
                                            :placeholder="'Enter Alternate Phone Number '+(alt_phone_index+1)"
                                            data-vv-as="Alternate Phone"
                                            :defaultCountry="alternate_phone.country_code"
                                            @onInput="departAltPhone($event, alt_phone_index)"
                                            v-validate="{min:12}"
                                            v-model="alternate_phone.phone_number">
                                        </vue-tel-input>
                                    </div>
                                </div>
                            </div>
                            <span class="invalid-feedback-form text-danger" v-show="errors.has('alternate_phone'+alt_phone_index)">
                                {{errors.first('alternate_phone'+alt_phone_index)}}
                            </span>
                        </div>
                    </div>
                    <div class="mt-10 pb-10">
                        <div class="d-flex align-items-center justify-content-center">
                            <button class="btn btn-outline-secondary" :disabled="create_api_called" @click="cancel" >Cancel</button>
                            <button class="btn btn-new-success ml-5" @click="addDepartment" :disabled="create_api_called || fields_not_changed" style="width: 120px;border-radius: 5px;">Save
                                <hollow-dots-spinner v-if="create_api_called" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-success v-if="create_success" :success_message="success_message" @hideSuccess="hideSuccess" modal_name="create-success"></create-success>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>

<script>
    import {HalfCircleSpinner} from 'epic-spinners';
    import companies from "../mixins/companies";
    const CreateSuccess = () => import('./CreateSuccess');
    import { SweetModal } from 'sweet-modal-vue';
    export default ({
        mixins: [companies],
        components: {
            CreateSuccess,
            HalfCircleSpinner,
            SweetModal
        },
        data() {
            return {
                business_unit_name_options:[],
                business_unit_name: '',
                department_head_options: [],
                create_api_called: false,
                warning_msg: '',
                success_msg: '',
                ajax_call_in_progress: false,
                is_show_hide_depart_info: true,
                is_show_hide_contact_info: true,
                is_started_on: false,
                department_head: '',
                primary_phone_type_options: [{
                        name: 'Home',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg'
                    },
                    {
                        name: 'Mobile',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                    },
                    {
                        name: 'Whatsapp',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                    },
                    {
                        name: 'Line',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                    },
                    {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    {
                        name: 'Telegram',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                    },
                    {
                        name: 'Others',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                    },
                    {
                        name: 'Messenger',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                    },
                    {
                        name: 'Fax',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                    }
                ],
                primary_phone_type: {
                    name: 'Work',
                    image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                },
                primary_phone_number: {
                    phone_number: '',
                    country_code: 'US',
                    dial_code: '1',
                },
                company_name: '',
                department_name: '',
                started_on: '',
                description: '',
                primary_email: '',
                alternate_emails: [],
                alternate_phone_numbers: [],
                business_unit_loading: false,
                depart_head_loading: false,
                create_success: false,
                success_message: '',
                primary_phone_number_id: '',
                company_department_domain_name: '',
                stringified_response: "",
                left_tab: false
            }
        },
        beforeRouteLeave (to, from, next) {
            if (this.left_tab == true) {
                    let message = "<img src='/static/images/exclamation-yellow.svg' style='width: 55px;margin-bottom: 15px;' /><br><div class='d-block'>Unsaved changes would be lost, Do you want to proceed?</div>";
                    let options = {
                    html: true,
                    loader: false,
                    reverse: false, // switch the button positions (left to right, and vise versa)
                    okText: 'Yes',
                    cancelText: 'No',
                    animation: 'fade',
                    type: 'basic',
                    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                    backdropClose: true,
                };
                this.$dialog.confirm(message, options)
                .then(function () {
                    next();
                })
                .catch(function () {
                    next(false);
                });
            } else {
                next()
            }
        },
        methods: {
            editDepartment(){
                this.$router.push('/departmentunit/edit');
            },
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            goToListView() {
                this.$router.push('/departmentunit/listview/cmpid/' + this.$route.params.company_id);
            },
            showHideContent(type){
                if(type == 'depart_info'){
                    this.is_show_hide_depart_info = !this.is_show_hide_depart_info;
                } else if(type == 'contact_info'){
                    this.is_show_hide_contact_info = !this.is_show_hide_contact_info;
                }
            },
            departPhone({number,isValid,country}) {
                this.primary_phone_number.phone_number = number.national;
                this.primary_phone_number.dial_code = country.dialCode;
                this.primary_phone_number.country_code = country.iso2;
            },
            departAltPhone({number,isValid,country}, phoneindex) {
                this.alternate_phone_numbers[phoneindex].phone_number = number.national;
                this.alternate_phone_numbers[phoneindex].dial_code = country.dialCode;
                this.alternate_phone_numbers[phoneindex].country_code = country.iso2;
            },
            hideSuccess() {
                this.create_success = false;
                this.left_tab = false
                setTimeout(() => {
                    this.$modal.hide(this.modal_name);
                }, 500);
                this.$router.push('/departmentunit/listview/cmpid/'+this.$route.params.company_id);
            },
            cancel() {
                this.$router.push('/departmentunit/listview/cmpid/'+this.$route.params.company_id);
            },
            addAlternatePhone() {
                this.alternate_phone_numbers.push(
                    {
                        phone_type: {
                            name: 'Work',
                            image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                        },
                        phone_number: "",
                        country_code: "US",
                        dial_code: "1"
                    }
                );
            },
            deleteAlternatePhone(index) {
                this.alternate_phone_numbers.splice(index, 1);
            },
            addAlternateEmail() {
                this.alternate_emails.push("");
            },
            deleteAlternateEmail(index) {
                this.alternate_emails.splice(index, 1);
            },
            async getCompanyInfo(id) {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getCompanyById(id);
                    if(response.status_id == 1) {
                        this.company_name = response.response.name;
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async getBusinesUnitOptions() {
                this.business_unit_loading = true;
                try {
                    let response = await this.getDepartmentBusinessUnitDropdown(this.$route.params.company_id);
                    if(response.status_id == 1) {
                        this.business_unit_name_options = response.response;
                    }
                    this.business_unit_loading = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async getDepartHeadOptions() {
                this.depart_head_loading = true;
                try {
                    let response = await this.getDepartmentHeadDropdown(this.$route.params.company_id);
                    if(response.status_id == 1) {
                        this.department_head_options = response.response;
                    }
                    this.depart_head_loading = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async createNewDepartment() {
                this.create_api_called = true;
                try {
                    if(this.$route.path.startsWith('/departmentunit/edit/cmpid/')) {
                        this.create_data.phone_number.id = this.primary_phone_number_id;
                        let response = await this.updateDepartmentUnitById(this.create_data, this.$route.params.id);
                        if(response.status_id == 1) {
                            this.success_message = response.message
                            this.create_success = true;
                            setTimeout(() => {
                                this.$modal.show('create-success');
                            }, 500);
                        } else {
                            this.create_api_called = false;
                            this.warning_msg = response.message;
                            this.$refs.warning_modal.open();
                        }
                    } else {
                        let response = await this.createDepartmentUnit(this.create_data);
                        if(response.status_id == 1) {
                            this.success_message = response.message
                            this.create_success = true;
                            setTimeout(() => {
                                this.$modal.show('create-success');
                            }, 500);
                        } else {
                            this.create_api_called = false;
                            this.warning_msg = response.message;
                            this.$refs.warning_modal.open();
                        }
                    }
                }
                catch(err) {
                    this.create_api_called = false;
                    this.warning_msg = err;
                    this.$refs.warning_modal.open();
                }
            },
            addDepartment() {
                this.$validator.validateAll().then(result => {
                    if (result == true && this.started_on != '') {
                        this.is_started_on = false;
                        this.create_data.alternate_phone_numbers.forEach((ph, index) => {
                            ph.dial_code = ph.phone_number.split(" ")[0] || "+91";
                            ph.phone_number = ph.phone_number.startsWith('+91') ? ph.phone_number.split(' ').splice(1,).join('') : ph.phone_number
                            ph.phone_type = ph.phone_type.name;
                        })
                        this.createNewDepartment();
                    } else {
                        if(this.started_on == '') {
                            this.is_started_on = true;
                        } else {
                            this.is_started_on = false;
                        }
                    }
                });
            },
            async getdepartmentUnitData(id) {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getDepartmentUnitById(id);
                    if(response.status_id == 1) {
                        let depart_edit_data = response.response;
                        this.business_unit_name = {
                            business_unit_id: depart_edit_data.business_unit_id || "",
                            business_unit_name: depart_edit_data.business_unit_name || "",
                        };
                        this.department_head = {
                            id: depart_edit_data.department_head_id || "",
                            name: depart_edit_data.department_head_name || "",
                        };
                        this.primary_phone_number = depart_edit_data.phone_number;
                        this.primary_phone_number_id = depart_edit_data.phone_number.id;
                        this.primary_phone_type = {
                            name: depart_edit_data.phone_number.phone_type,
                            image: ''
                        };
                        this.department_name = depart_edit_data.name;
                        this.started_on = depart_edit_data.started_on ? depart_edit_data.started_on.split("-")[2]+"/"+depart_edit_data.started_on.split("-")[1]+"/"+depart_edit_data.started_on.split("-")[0] : "";
                        this.description = depart_edit_data.description;
                        this.company_department_domain_name = depart_edit_data.primary_email.split("@")[1];
                        this.primary_email = depart_edit_data.primary_email.split("@")[0];
                        this.alternate_emails = depart_edit_data.alternate_email;
                        this.alternate_phone_numbers = depart_edit_data.alternate_phone_numbers
                    }
                    this.ajax_call_in_progress = false;
                    this.stringified_response = JSON.stringify(this.create_data)
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                    // console.log(err);
                }
            }
        },
        mounted() {
            if(this.$route.params.company_id) {
                this.getCompanyInfo(this.$route.params.company_id);
            }
            if(this.$route.path.startsWith('/departmentunit/edit/cmpid/')) {
                // setTimeout(() => {
                    this.getdepartmentUnitData(this.$route.params.id);
                // }, 1000);
            }
            if(this.$route.path.startsWith('/departmentunit/create/cmpid/')) {
                this.company_department_domain_name = localStorage.getItem("company_department_domain_name");
            }
        },
        computed: {
            fields_not_changed(){
                if(this.stringified_response == JSON.stringify(this.create_data)){
                    this.left_tab = false
                }else{
                    this.left_tab = true
                }
                return this.stringified_response == JSON.stringify(this.create_data)
            },
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
            create_data() {
                return {
                    name: this.department_name,
                    company_id: this.$route.params.company_id,
                    business_unit_id: this.business_unit_name.business_unit_id || "",
                    started_on: this.started_on ? this.started_on.split("/")[2]+"-"+this.started_on.split("/")[1]+"-"+this.started_on.split("/")[0] : "",
                    description: this.description,
                    alternate_emails: this.alternate_emails,
                    department_head_id: this.department_head.id || "",
                    primary_email: this.primary_email+'@'+this.company_department_domain_name.replace(/.+\/\/|www.|\+/g, ''),
                    phone_number: {
                        phone_type: this.primary_phone_type.name,
                        phone_number: this.primary_phone_number.phone_number,
                        country_code: this.primary_phone_number.country_code,
                        dial_code: this.primary_phone_number.dial_code
                    },
                    alternate_phone_numbers: this.alternate_phone_numbers
                }
            }
        },
        watch: {
            started_on: function(val) {
                if(val) {
                    // this.started_on = val.split("/").reverse().join("-");
                    this.is_started_on = false;
                }
            },
        },
        created() {
            const dict = {
                custom: {
                    department_name:{
                        required:() => "Department name is required",
                    },
                    primary_email: {
                        required:() => "Primary email is required",
                        regex: () => "Enter valid email address.",
                    },
                    primary_phone: {
                        required:() => "Primary phone is required",
                        min: () => "Phone Number must contain more than 9 numbers.",
                    },
                    business_unit_name:{
                        required:() => "Business unit name is required",
                    },
                    department_head:{
                        required:() => "Department head is required",
                    },
                }
            }
            this.$validator.localize('en', dict)
        }
    })
</script>

<style scoped>
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 50px 0;
        border-radius: 6px;
        padding-bottom: 0px !important;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 4px 10px;
    }
    .input-field {
        border-radius: 6px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding: 8px 10px;
        width: 100%;
    }
    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }
    .btn-new-secondary {
        color: #fff;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        line-height: 30px;
        box-shadow: none;
        float:right!important;
    }
    @media only screen and (max-width: 768px){
        .bottom-padding{
            padding-bottom: 150px !important;
            margin-right: 5px !important;
        }
    }
    .input-box {
        height: 38px !important;
    }
    .btn-outline-secondary {
        color: #e82828;
        border: 1px solid #e82828 !important;
        width: 120px;
        border-radius: 5px;
    }
    .btn-outline-secondary:hover {
        color: #fff;
        background-color: #e82828;
        border-color: #e82828 !important;
    }
    .btn-new-success:disabled{
        cursor: no-drop !important;
    }
    .btn:disabled{
        pointer-events: auto !important
    }
</style>